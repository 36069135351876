import React from 'react';

const ClaveUnica = ({handleInputChange, editarSecreto , datos, setDatos}) => {

    return ( 
        <>
                  <span className='mb-2'> Rut (11111111-1) <strong className='text-danger'>*</strong> </span>
          <div className='form-group mb-4'>

               <input 
                    type='text' 
                    value={datos.rut}
                    name='rut' 
                    className='form-control' 
                    placeholder='Ingrese el rut del propietario'
                    onChange={ handleInputChange }
                    />
                    
             </div> 
      
             <div className='form-group mb-4'>
             <label>Clave única <strong className='text-danger'>*</strong> </label>
               <input 
               type='password' 
               name='password' 
               value={datos.password}
               className='form-control' 
               placeholder='Ingresa tu clave única'
               onChange={ handleInputChange }
               />
             </div>
             <div className='form-group mb-4'>
             <label>Confirmar clave única <strong className='text-danger'>*</strong> </label>
               <input 
               type='password' 
               value={datos.confirmar}
               name='confirmar' 
               className='form-control' 
               placeholder='Ingresa tu clave única nuevamente'
               onChange={ handleInputChange }
               />
             </div>
             <div className='form-group mb-5 mt-5'>
               <button 
               type='submit' 
               className='form-control btn btn-primary'                
                >
                   Guardar Secreto
               </button>
              
             </div>
        </>
     );
}
 
export default ClaveUnica;