import React from 'react';
import FormLogin from '../../components/Login';
import secure from '../../assets/images/security.gif'
import { useLocation } from 'react-router-dom';
const LoginPage = () => {

    const location = useLocation();

    return ( 
        <>
                 <div >
    <div className="card card0 border-0">
       <div className='container mt-5'>
       <div className="row d-flex ">
        <div className="row"> <img src="https://www.orbisdata.cl/wp-content/uploads/2021/09/Logo_Orbis-Data.svg" className="logo"/> </div>
            <div className="col-lg-6 margin-top-login ">
                <div className="card1 pb-5 mt-5">
                    <div className="row px-3 justify-content-center mt-4 mb-5 border-line ">  <img src={secure} className='image' /> </div>
                </div>
            </div>
            <div className="col-lg-6 margin-top-login">
             <FormLogin/>
            </div>
        </div>
       </div>
        <div className="bg-blue py-4 mt-5 margin-bottom-login ">
            <div className="row px-3"> <small className="ml-4 ml-sm-5 mb-2">Copyright &copy; 2022. Todos los derechos reservados.</small>
                <div className="social-contact ml-4 ml-sm-auto"> <span className="fa fa-facebook mr-4 text-sm"></span> <span className="fa fa-google-plus mr-4 text-sm"></span> <span className="fa fa-linkedin mr-4 text-sm"></span> <span className="fa fa-twitter mr-4 mr-sm-5 text-sm"></span> </div>
            </div>
        </div>
    </div>
</div>
    
        </>
     );
}
 
export default LoginPage;