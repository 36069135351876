import React from 'react';

const UsuarioClave = ({ handleInputChange, editarSecreto, datos, setDatos, setDisabled }) => {

    return (
        <>
            <span className='mb-2'> Rut (11111111-1) <strong className='text-danger'>*</strong> </span>
            <div className='form-group mb-4'>

                <input
                    type='text'
                    value={datos.rut}
                    name='rut'
                    className='form-control'
                    placeholder='Ingrese el rut del propietario'
                    onChange={handleInputChange}
                />

            </div>

            <div className='form-group mb-4'>
                <label>Usuario <strong className='text-danger'>*</strong> </label>
                <input
                    type='text'
                    name='usuario'
                    value={datos.usuario}
                    className='form-control'
                    placeholder='Ingresa tu usuario'
                    onChange={handleInputChange}
                />
            </div>

            <div className='form-group mb-4'>
                <label>Clave <strong className='text-danger'>*</strong> </label>
                <input
                    type='password'
                    name='contrasena'
                    value={datos.contrasena}
                    className='form-control'
                    placeholder='Ingresa tu clave'
                    onChange={handleInputChange}
                />
            </div>
            <div className='form-group mb-4'>
                <label>Confirmar Clave <strong className='text-danger'>*</strong> </label>
                <input
                    type='password'
                    value={datos.confirmarContrasena}
                    name='confirmarContrasena'
                    className='form-control'
                    placeholder='Ingresa tu clave nuevamente'
                    onChange={handleInputChange}
                />
            </div>
            <div className='form-group mb-5 mt-5'>
                <button
                    type='submit'
                    className='form-control btn btn-primary'
                    disabled={setDisabled}
                >
                    Guardar Secreto
                </button>

            </div>
        </>
    );
}

export default UsuarioClave;