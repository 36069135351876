import React from 'react';

const Banca = ({handleInputChange, editarSecreto , datos, setDatos}) => {

  

    return ( 
    <>
        <span className='mb-2'> Rut (11111111-1) <strong className='text-danger'>*</strong> </span>
          <div className='form-group mb-4'>

               <input 
                    type='text' 
                    name='rut' 
                    value={datos.rut}
                    className='form-control' 
                    placeholder='Ingrese el rut del propietario'
                    onChange={ handleInputChange }
                    />
                    
             </div>
             <div className='form-group mb-4' >
           <label>Número de cuenta bancaria <strong className='text-danger'>*</strong> </label>
             <input 
             type='text' required 
             name='cuenta' 
             value={datos.cuenta}
             className='form-control' 
             placeholder='N° Cuenta'
             onChange={ handleInputChange }
             />
           </div>
           <div className='form-group mb-4'>
           <label>Banco <strong className='text-danger'>*</strong> </label>
             <input 
             type='text' required 
             name='banco' 
             value={datos.banco}
             className='form-control' 
             placeholder='Ingresa tu banco'
             onChange={ handleInputChange }
             />
           </div>
           <div className='form-group mb-4'>
           <label>Ingresa tu tipo de cuenta <strong className='text-danger'>*</strong> </label>
             <input 
             type='text' required 
             name='tipoCuenta' 
             value={datos.tipoCuenta}
             className='form-control' 
             placeholder='Tipo de cuenta'
             onChange={ handleInputChange }
             />
           </div>
           <div className='form-group mb-4'>
           <label>Clave <strong className='text-danger'>*</strong> </label>
             <input 
             type='password' 
             name='password' 
             value={datos.password}
             className='form-control' 
             placeholder='Clave'
             onChange={ handleInputChange }
             />
           </div>
           <div className='form-group mb-5 mt-5'>
               <button 
               type='submit' 
               className='form-control btn btn-primary'                
                >
                   Guardar Secreto
               </button>
              
             </div>
    </>
     );
}
 
export default Banca; 