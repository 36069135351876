import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginPage from "./pages/login";
import CrearSecreto from "./pages/crearSecreto";
import Navbar from './components/Navbar'
import PrivateRoute from './utils/PrivateRoute';
import ListarSecretos from './pages/ListarSecretos';
import CambiarContrasena  from './pages/cambiarContrasena';


function App() {

  return (
    <Router > 
      <Routes>
      <Route exact path="/" element={<LoginPage/>}/>  
        <Route exact path='/' element={<PrivateRoute/>}>         
            <Route  exact path="/crear-secreto" element={[<Navbar key={1}/> , <CrearSecreto key={2}/>]} /> 
            <Route  exact path="/listar-secretos" element={[<Navbar key={3}/> , <ListarSecretos key={4}/>]} /> 
            <Route  exact path="/cambiar-contrasena" element={[<Navbar key={5}/> , <CambiarContrasena key={6}/>]} /> 
        </Route>
      </Routes> 
    </Router>

  );
}

export default App;
