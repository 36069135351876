import React from 'react';

const UsuarioClave = ({ guardarSecreto, handleInputChange }) => {
    return (
        <>
            <div className='form-group mb-4'>
                <label>Usuario <strong className='text-danger'>*</strong> </label>
                <input
                    type='text'
                    name='usuario'
                    max={30}
                    className='form-control'
                    placeholder='Ingresa tu usuario'
                    required
                    onChange={handleInputChange}
                />
            </div>
            <div className='form-group mb-4'>
                <label>Clave <strong className='text-danger'>*</strong> </label>
                <input
                    type='password'
                    name='contrasena'
                    className='form-control'
                    placeholder='Ingresa tu clave'
                    required
                    onChange={handleInputChange}
                />
            </div>
            <div className='form-group mb-4'>
                <label>Confirmar Clave <strong className='text-danger'>*</strong> </label>
                <input
                    type='password'
                    name='confirmarContrasena'
                    className='form-control'
                    placeholder='Ingresa tu clave nuevamente'
                    required
                    onChange={handleInputChange}
                />
            </div>
            <button
                type='submit'
                className='form-control btn btn-primary mt-5'
                onSubmit={guardarSecreto} >
                Guardar Secreto
            </button>
        </>
    );
}

export default UsuarioClave;