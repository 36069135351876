import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { isExpired, decodeToken } from "react-jwt";

const PrivateRoute = () => {
   
    let auth = localStorage.getItem('token'); 
    if(auth){

        const isMyTokenExpired = isExpired(auth); 
        if(isMyTokenExpired == true ){
         auth = localStorage.removeItem('token');
         auth = '';
        }
        
    }else{
        auth = '';
    }
  

    return auth.length > 1 ? <Outlet /> : <Navigate to="/" />;
}

export default PrivateRoute;