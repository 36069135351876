import React, { useEffect, useState } from 'react';
import api from '../../utils/api/api';

const TarjetaCredito = ({handleInputChange, editarSecreto, datos, setDatos, handleFocusChange, handleFocusOutChange, children}) => {

const [bancos, setBancos] = useState([]);
const [isCoordenadas, setIsCoordenadas] = useState(datos.isCoordenadas);

useEffect(async ()=> {
  try {
    const consultarBancos = await   api.get('secretos/bancos');
    setBancos(consultarBancos.data);
  } catch (e) {
    console.log(e);
  }

 
},[]);

    return ( 
    <>
        <span className='mb-2'> Rut (11111111-1) <strong className='text-danger'>*</strong> </span>
          <div className='form-group mb-4'>
               <input 
                    type='text' 
                    name='rut' 
                    value={datos.rut}
                    className='form-control' 
                    placeholder='Ingrese el rut del propietario'
                    onChange={ handleInputChange }
                    />
                    
             </div>
             <div className='form-group mb-4'>
           <label>Banco <strong className='text-danger'>*</strong> </label>
           <select value={datos.banco} name='banco' className='form-control' onChange={ handleInputChange }>
                  <option   value=""> --Selecciona un valor --</option>   
                  {
                                    bancos.length > 0 ? (
                                      bancos.map(cont => (
                                            <option key={cont.banc_id}
                                            value={cont.banc_nombre}>{cont.banc_nombre}</option>
                                            ))
                                            ) : (
                                           null
                                            )
                  }
         

                </select> 
           </div>
             <div className='form-group mb-4'>
               {children}
             </div>
           <div className='form-group mb-4'>
           <label>Nombre <strong className='text-danger'>*</strong> </label>
             <input 
             type='text' required 
             name='name' 
             value={datos.name}
             className='form-control' 
             placeholder='Tipo de cuenta'
             onChange={ handleInputChange }
             onFocus= { handleFocusChange }
             />
           </div>
           <div className='form-group mb-4'>
           <label>Número de tarjeta <strong className='text-danger'>*</strong> </label>
             <input 
             type='text' required 
             className='form-control' 
             name="number"
             value={datos.number.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()}
             placeholder="Número de tarjeta"
             onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()  }}
             onFocus= { handleFocusChange }
             maxLength={19}
             />
           </div>
           <div className='form-group mb-4'>
           <label>Vencimiento <strong className='text-danger'>*</strong> </label>
             <input 
             type='text' required 
             name='expiry'
             value={datos.expiry.replace(/[^\dA-Z]/g, '').replace(/(.{2})/, '$1/').trim()}
             className='form-control' 
             placeholder='06/27'
             onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{2})/, '$1/').trim()  } }
             onFocus= { handleFocusChange }
             maxLength={5}
             />
           </div>
           <div className='form-group mb-4'>
           <label>CVV <strong className='text-danger'>*</strong> </label>
             <input 
             type='text' 
             name='cvc' 
             required
             className='form-control' 
             placeholder='123'
             maxLength={3}
             value={datos.cvc}
             onFocus= { handleFocusChange }
             onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") } }
             onBlur={handleFocusOutChange}
             />
           </div>
           <div className='form-group mb-4'>
           <label>Clave <strong className='text-danger'>*</strong> </label>
             <input 
             type='password' 
             name='password' 
             required
             className='form-control' 
             placeholder='Clave'
             value={datos.password}
             onChange={ handleInputChange }
             />
           </div>
           <div className='form-group mb-4'>
           <input
           onChange={ (e) => {handleInputChange(e), setIsCoordenadas(e.target.checked)}}
           className='form-check-input'
           type='checkbox'
           checked={datos.isCoordenadas}
           name='isCoordenadas'
           id='flexCheckDefault'/>
            <label className="form-check-label" htmlFor="flexCheckDefault">
            &nbsp;&nbsp;Tarjeta de coordenadas
            </label>
           </div>
           {datos.isCoordenadas && 
           <>
           <div className='card text-white' style={{backgroundColor: '#027ADE'}}>
              
             <div className='card-body'>
                <table className='table'>
                  <thead>
                    <tr className='text-center text-white'>
                    <td></td>
                    <td>A</td>
                    <td>B</td>
                    <td>C</td>
                    <td>D</td>
                    <td>E</td>
                    <td>F</td>
                    <td>G</td>
                    <td>H</td>
                    <td>I</td>
                    <td>J</td>
                    </tr>                   
                  </thead>
                  <tbody className='text-white'>
                     <tr>
                       <td>1</td>
                       <td><input type='text' maxLength={2} required name='A1' value={datos.A1} className='form-control'  placeholder='A1' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='B1' value={datos.B1} className='form-control'  placeholder='B1' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='C1' value={datos.C1} className='form-control'  placeholder='C1' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='D1' value={datos.D1} className='form-control'  placeholder='D1' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='E1' value={datos.E1} className='form-control'  placeholder='E1' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='F1' value={datos.F1} className='form-control'  placeholder='F1' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='G1' value={datos.G1} className='form-control'  placeholder='G1' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='H1' value={datos.H1} className='form-control'  placeholder='H1' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='I1' value={datos.I1} className='form-control'  placeholder='I1' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='J1' value={datos.J1} className='form-control'  placeholder='J1' onChange={ handleInputChange } /></td>
                     </tr>
                     <tr>
                       <td>2</td>
                       <td><input type='text' maxLength={2} required name='A2' value={datos.A2}  className='form-control'  placeholder='A2' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='B2' value={datos.B2}  className='form-control'  placeholder='B2' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='C2' value={datos.C2}  className='form-control'  placeholder='C2' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='D2' value={datos.D2}  className='form-control'  placeholder='D2' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='E2' value={datos.E2}  className='form-control'  placeholder='E2' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='F2' value={datos.F2}  className='form-control'  placeholder='F2' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='G2' value={datos.G2}  className='form-control'  placeholder='G2' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='H2' value={datos.H2}  className='form-control'  placeholder='H2' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='I2' value={datos.I2}  className='form-control'  placeholder='I2' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='J2' value={datos.J2}  className='form-control'  placeholder='J2' onChange={ handleInputChange } /></td>
                     </tr>
                     <tr>
                       <td>3</td>
                       <td><input type='text' maxLength={2} required name='A3' value={datos.A3}  className='form-control'  placeholder='A3' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='B3' value={datos.B3}  className='form-control'  placeholder='B3' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='C3' value={datos.C3}  className='form-control'  placeholder='C3' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='D3' value={datos.D3}  className='form-control'  placeholder='D3' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='E3' value={datos.E3}  className='form-control'  placeholder='E3' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='F3' value={datos.F3}  className='form-control'  placeholder='F3' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='G3' value={datos.G3}  className='form-control'  placeholder='G3' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='H3' value={datos.H3}  className='form-control'  placeholder='H3' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='I3' value={datos.I3}  className='form-control'  placeholder='I3' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='J3' value={datos.J3}  className='form-control'  placeholder='J3' onChange={ handleInputChange } /></td>
                     </tr>
                     <tr>
                       <td>4</td>
                       <td><input type='text' maxLength={2} required name='A4' value = {datos.A4}  className='form-control'  placeholder='A4' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='B4' value = {datos.B4}  className='form-control'  placeholder='B4' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='C4' value = {datos.C4}  className='form-control'  placeholder='C4' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='D4' value = {datos.D4}  className='form-control'  placeholder='D4' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='E4' value = {datos.E4}  className='form-control'  placeholder='E4' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='F4' value = {datos.F4}  className='form-control'  placeholder='F4' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='G4' value = {datos.G4}  className='form-control'  placeholder='G4' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='H4' value = {datos.H4}  className='form-control'  placeholder='H4' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='I4' value = {datos.I4}  className='form-control'  placeholder='I4' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='J4' value = {datos.J4}  className='form-control'  placeholder='J4' onChange={ handleInputChange } /></td>
                     </tr>
                     <tr>
                       <td>5</td>
                       <td><input type='text' maxLength={2} required name='A5' value={datos.A5}  className='form-control'  placeholder='A5' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='B5' value={datos.B5}  className='form-control'  placeholder='B5' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='C5' value={datos.C5}  className='form-control'  placeholder='C5' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='D5' value={datos.D5}  className='form-control'  placeholder='D5' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='E5' value={datos.E5}  className='form-control'  placeholder='E5' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='F5' value={datos.F5}  className='form-control'  placeholder='F5' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='G5' value={datos.G5}  className='form-control'  placeholder='G5' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='H5' value={datos.H5}  className='form-control'  placeholder='H5' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='I5' value={datos.I5}  className='form-control'  placeholder='I5' onChange={ handleInputChange } /></td>
                       <td><input type='text' maxLength={2} required name='J5' value={datos.J5}  className='form-control'  placeholder='J5' onChange={ handleInputChange } /></td>
                     </tr>
                   </tbody>
                </table>
             </div>
           </div>
           <div className='form-group mb-4 mt-3'>
           <label>Número tarjeta de Coordenadas <strong className='text-danger'>*</strong> </label>
             <input 
             type='text' 
             name='numeroTarjeta' 
             required
             value={datos.numeroTarjeta}
             className='form-control' 
             placeholder='123456789'
             onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") } }
             />
           </div>
           </>
          }
           <div className='form-group mb-5 mt-5'>
               <button 
               type='submit' 
               className='form-control btn btn-primary'                
                >
                   Guardar Secreto
               </button>
              
             </div>
    </>
     );
}
 
export default TarjetaCredito; 