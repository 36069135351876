import React, { useEffect, useState } from 'react';
import api from '../../utils/api/api';

const Coordenadas = ({handleInputChange, editarSecreto, datos, setDatos}) => {

  
const [bancos, setBancos] = useState([]);

useEffect(async ()=> {
  try {
    const consultarBancos = await   api.get('secretos/bancos');
    setBancos(consultarBancos.data);
  } catch (e) {
    console.log(e);
  }

 
},[]);

    return ( 
        <>
           <span className='mb-2'> Rut (11111111-1) <strong className='text-danger'>*</strong> </span>
          <div className='form-group mb-4'>

               <input 
                    type='text' 
                    value={datos.rut}
                    name='rut' 
                    className='form-control' 
                    placeholder='Ingrese el rut del propietario'
                    onChange={ handleInputChange }
                    />
                    
             </div>
             <div className='form-group mb-4 mt-3'>
           <label>Número tarjeta de Coordenadas <strong className='text-danger'>*</strong> </label>
             <input 
             type='text' 
             value={datos.tarjeta}
             name='tarjeta' 
             className='form-control' 
             placeholder='123456789'
             onChange={ handleInputChange }
             />
           </div>
           <div className='form-group mb-4'>
           <label>Banco <strong className='text-danger'>*</strong> </label>
           <select value={datos.banco} name='banco' className='form-control' onChange={ handleInputChange }>
                  <option   value=""> --Selecciona un valor --</option>   
                  {
                                    bancos.length > 0 ? (
                                      bancos.map(cont => (
                                            <option key={cont.banc_id}
                                            value={cont.banc_nombre}>{cont.banc_nombre}</option>
                                            ))
                                            ) : (
                                           null
                                            )
                  }
         

                </select> 
           </div>
        
        <div className='card text-white' style={{backgroundColor: '#027ADE'}}>
              
              <div className='card-body'>
                 <table className='table'>
                   <thead>
                     <tr className='text-center text-white'>
                     <td></td>
                     <td>A</td>
                     <td>B</td>
                     <td>C</td>
                     <td>D</td>
                     <td>E</td>
                     <td>F</td>
                     <td>G</td>
                     <td>H</td>
                     <td>I</td>
                     <td>J</td>
                     </tr>                   
                   </thead>
                   <tbody className='text-white'>
                     <tr>
                       <td>1</td>
                       <td><input type='text' required name='A1' value={datos.A1} className='form-control'  placeholder='A1' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='B1' value={datos.B1} className='form-control'  placeholder='B1' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='C1' value={datos.C1} className='form-control'  placeholder='C1' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='D1' value={datos.D1} className='form-control'  placeholder='D1' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='E1' value={datos.E1} className='form-control'  placeholder='E1' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='F1' value={datos.F1} className='form-control'  placeholder='F1' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='G1' value={datos.G1} className='form-control'  placeholder='G1' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='H1' value={datos.H1} className='form-control'  placeholder='H1' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='I1' value={datos.I1} className='form-control'  placeholder='I1' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='J1' value={datos.J1} className='form-control'  placeholder='J1' onChange={ handleInputChange } /></td>
                     </tr>
                     <tr>
                       <td>2</td>
                       <td><input type='text' required name='A2' value={datos.A2}  className='form-control'  placeholder='A2' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='B2' value={datos.B2}  className='form-control'  placeholder='B2' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='C2' value={datos.C2}  className='form-control'  placeholder='C2' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='D2' value={datos.D2}  className='form-control'  placeholder='D2' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='E2' value={datos.E2}  className='form-control'  placeholder='E2' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='F2' value={datos.F2}  className='form-control'  placeholder='F2' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='G2' value={datos.G2}  className='form-control'  placeholder='G2' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='H2' value={datos.H2}  className='form-control'  placeholder='H2' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='I2' value={datos.I2}  className='form-control'  placeholder='I2' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='J2' value={datos.J2}  className='form-control'  placeholder='J2' onChange={ handleInputChange } /></td>
                     </tr>
                     <tr>
                       <td>3</td>
                       <td><input type='text' required name='A3' value={datos.A3}  className='form-control'  placeholder='A3' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='B3' value={datos.B3}  className='form-control'  placeholder='B3' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='C3' value={datos.C3}  className='form-control'  placeholder='C3' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='D3' value={datos.D3}  className='form-control'  placeholder='D3' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='E3' value={datos.E3}  className='form-control'  placeholder='E3' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='F3' value={datos.F3}  className='form-control'  placeholder='F3' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='G3' value={datos.G3}  className='form-control'  placeholder='G3' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='H3' value={datos.H3}  className='form-control'  placeholder='H3' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='I3' value={datos.I3}  className='form-control'  placeholder='I3' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='J3' value={datos.J3}  className='form-control'  placeholder='J3' onChange={ handleInputChange } /></td>
                     </tr>
                     <tr>
                       <td>4</td>
                       <td><input type='text' required name='A4' value = {datos.A4}  className='form-control'  placeholder='A4' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='B4' value = {datos.B4}  className='form-control'  placeholder='B4' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='C4' value = {datos.C4}  className='form-control'  placeholder='C4' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='D4' value = {datos.D4}  className='form-control'  placeholder='D4' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='E4' value = {datos.E4}  className='form-control'  placeholder='E4' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='F4' value = {datos.F4}  className='form-control'  placeholder='F4' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='G4' value = {datos.G4}  className='form-control'  placeholder='G4' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='H4' value = {datos.H4}  className='form-control'  placeholder='H4' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='I4' value = {datos.I4}  className='form-control'  placeholder='I4' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='J4' value = {datos.J4}  className='form-control'  placeholder='J4' onChange={ handleInputChange } /></td>
                     </tr>
                     <tr>
                       <td>5</td>
                       <td><input type='text' required name='A5' value={datos.A5}  className='form-control'  placeholder='A5' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='B5' value={datos.B5}  className='form-control'  placeholder='B5' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='C5' value={datos.C5}  className='form-control'  placeholder='C5' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='D5' value={datos.D5}  className='form-control'  placeholder='D5' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='E5' value={datos.E5}  className='form-control'  placeholder='E5' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='F5' value={datos.F5}  className='form-control'  placeholder='F5' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='G5' value={datos.G5}  className='form-control'  placeholder='G5' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='H5' value={datos.H5}  className='form-control'  placeholder='H5' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='I5' value={datos.I5}  className='form-control'  placeholder='I5' onChange={ handleInputChange } /></td>
                       <td><input type='text' required name='J5' value={datos.J5}  className='form-control'  placeholder='J5' onChange={ handleInputChange } /></td>
                     </tr>
                   </tbody>
                 </table>
              </div>
            </div>

            <div className='form-group mb-5 mt-5'>
               <button 
               type='submit' 
               className='form-control btn btn-primary'                
                >
                   Guardar Secreto
               </button>
              
             </div>
        </>
     );
}
 
export default Coordenadas;