import React from 'react';
import FormCambiarContrasena from '../../components/FormCambiarContrasena';
import user from '../../assets/images/password.gif'
const CambiarContrasena = () => {
    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-7 '>
                        <FormCambiarContrasena />
                    </div>
                    <div className='col-sm-5'>
                        <img src={user} className='img-fluid  ' />
                    </div>
                </div>
            </div>
        </>
    );
}

export default CambiarContrasena;