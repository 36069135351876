import React, { useContext, useEffect, useState } from 'react';
import api from '../../utils/api/api';
import { MdDeleteOutline,MdModeEdit  } from 'react-icons/md';
import Swal from 'sweetalert2'
import {UpdateSecretoContext} from '../../utils/context/Secreto';
 


const TablaSecretos = () => {

    const {id,setId} = useContext(UpdateSecretoContext);

 
    const [secretos, setSecretos] = useState([]);

    const tabla = async () => {

        try {
            const id = localStorage.getItem('id');
            const cargaTabla = await  api.get(`secretos/get/lista-secretos/${id}`);
          
            setSecretos(cargaTabla.data);


        } catch (e) {
            console.log(e);
        }
    
    }
    
    useEffect(()=> {

     tabla();

    }, []);


    const eliminarSecreto = async (id,tipo) => {

        Swal.fire({
            title: '¿Seguro que deseas eliminar este secreto?',
            text: "Cuando eliminas un secreto no puede ser recuperado",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonText: 'Cancelar',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminalo'
          }).then(async (result)   => {
            if (result.isConfirmed) {
                try {
                    const secreto = await api.delete(`secretos/delete/secreto/${id}/${tipo}`);  
                       tabla();

                       Swal.fire(
                        'Eliminado!',
                        secreto.data.msg,
                        'success'
                      )

                   } catch (e) {
                       console.log(e);
                   }

            }
          })
      
    }

    const validarUpdate = async (id, tipo) => {
        Swal.fire({
            title: 'Ingresa tu contraseña',
            text: 'Por motivos de seguridad para editar tus secretos, se te solicitará tu contraseña, si esta es incorrecta no tendrás acceso a la edición',
            input: 'password',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Enviar',
            showLoaderOnConfirm: true,
            preConfirm: async (login) => {
            const permiso =  await  api.post('users/get/cliente', {
                id : localStorage.getItem('id'),
                password : login
             });

            return   permiso.data
                
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.value ) {
                setId({id:id, tipo:tipo});
              Swal.fire({
                title: `Editar!`,
              })
            } 
          });
    } 

    
    return (  
     <table className='table  table-bordered'>
        <thead className='bg-primary text-white text-center'>
            <tr>
                <td>Código</td>
                <td colSpan={2}>Acciones</td>
            </tr>
        </thead>
        <tbody className='text-center'>
        {
                                    secretos.length > 0 ? (
                                        secretos.map(secreto => (
                                            <tr key={secreto.dats_id}>
                                                <td>{secreto.dats_id}
                                                </td>
                                                <td><button className='btn btn-primary' onClick={ ()=> eliminarSecreto(secreto.dats_id,secreto.tids_id)}><MdDeleteOutline /></button></td>
                                                <td><button className='btn btn-primary' onClick={ ()=> validarUpdate(secreto.dats_id,secreto.tids_id)}><MdModeEdit /></button></td>
                                            </tr>
                                            
                                            ))
                                            ) : (
                                           <tr><td colSpan={3}>No tienes secretos disponibles</td></tr>
                                            )
                                }
        </tbody>
         
     </table>
    );
}
 
export default TablaSecretos;