import React, {useState} from 'react';
import  '../../assets/css/stylesLogin.css';
import {  useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import api from '../../utils/api/api';


 

const FormLogin = () => {
    const history = useNavigate();
 
    const [login, setLogin] = useState({
        user: '',
        password: ''
    });

    const handleInputChange = (e) => {
        setLogin({
            ...login,
            [ e.target.name] : e.target.value
        });

      }

    const autenticar = async (e) => {
        e.preventDefault();
      
       try {
        const autentificacion = await api.post('users/auth-cliente', {
              email: login.user,
              password: login.password           
        }).catch(function (error) {
            if (error.response) {
                Swal.fire({
                    icon: 'error',
                    title: 'Revisa tus credenciales',
                    text: error.response.data.msg
                  });
            
            };

        });
 
 

        if(autentificacion){
            localStorage.setItem('token', autentificacion.data.token);
             localStorage.setItem('id', autentificacion.data.id);
            if(autentificacion.status == 200){
                history('/crear-secreto');
            }
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Revisa tus credenciales',
                text: 'Email y/o contraseña incorrecta',
              });
        }

        
       
      
        



       } catch (e) {
        console.log("error::"+e)   
       }
     
    }  

    return (
       <>
     <div className="card2 card border-0 px-4 py-5 mt-5">
                    
                    <div className="row px-3 mb-4">
                    </div>
                    <form onSubmit={autenticar}>
                    <div className="row px-3"> <label className="mb-1">
                            <h6 className="mb-0 text-sm">Dirección de correo</h6>
                        </label> <input className="mb-4 form-control" type="text" required   onChange={ handleInputChange }  name="user" placeholder="Ingrese un email"/> </div>
                    <div className="row px-3"> <label className="mb-1">
                            <h6 className="mb-0 text-sm">Contraseña</h6>
                        </label> <input type="password" required className='form-control mb-3'  onChange={ handleInputChange } name="password" placeholder="Ingrese su contraseña"/> </div>
                 
                    <div className="row mb-3 px-3"> <button type="submit" className="btn btn-blue w-100 text-center">Iniciar sesión</button> </div>
                    </form>
                    <div className="row mb-4 px-3"> <small className="font-weight-bold">No tienes una cuenta? <a className="text-danger ">Contrata o da de alta tu servicio aquí</a></small> </div>
                </div>

         
       
       
 </>
      );
}
 
export default FormLogin;
