import React, { useEffect, useState } from 'react';
import api from '../../utils/api/api';

const TarjetaCredito = ({guardarSecreto, handleInputChange, handleFocusChange, handleFocusOutChange, children}) => {

const [bancos, setBancos] = useState([]);
const [isCoordenadas, setIsCoordenadas] = useState(false);

const [tipoCuentas, setTipoCuentas] = useState([]);

  useEffect(async ()=> {
    try {
      const consultarBancos = await   api.get('secretos/bancos');
      setBancos(consultarBancos.data);
    } catch (e) {
      console.log(e);
    }

    try {
      const consultarTipos = await   api.get('secretos/tipo-cuenta');
      setTipoCuentas(consultarTipos.data);
    } catch (e) {
      console.log(e);
    }
},[]);


    return (

       <>

           <div className='form-group mb-4'>
           <label>Banco <strong className='text-danger'>*</strong> </label>
           <select defaultValue='' required name='banco' className='form-control' onChange={ handleInputChange }>
                  <option   value=""> --Selecciona un valor --</option>   
                  {
                                    bancos.length > 0 ? (
                                      bancos.map(cont => (
                                            <option key={cont.banc_id}
                                            value={cont.banc_nombre}>{cont.banc_nombre}</option>
                                            ))
                                            ) : (
                                           null
                                            )
                                }
         

                </select> 
           </div>
          <div className='form-group mb-4' >
            {children}
           <label>Número de tarjeta <strong className='text-danger'>*</strong> </label>
             <input 
             type='text' required 
             className='form-control' 
             name="number"
             placeholder="Número de tarjeta"
             onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()  }}
             onFocus= { handleFocusChange }
             maxLength={19}
             />
           </div>
           <div className='form-group mb-4' >
           <label>Fecha de expiración <strong className='text-danger'>*</strong> </label>
             <input 
             type='text' required 
             name='expiry'
             className='form-control' 
             placeholder='06/27'
             onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{2})/, '$1/').trim()  } }
             onFocus= { handleFocusChange }
             maxLength={5}
             />
           </div>
           <div className='form-group mb-4'>
           <label>CVV <strong className='text-danger'>*</strong> </label>
             <input 
             type='text' 
             name='cvc' 
             required
             className='form-control' 
             placeholder='123'
             maxLength={3}
             onFocus= { handleFocusChange }
             onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") } }
             onBlur={handleFocusOutChange}
             />
           </div>
           <div className='form-group mb-4'>
           <label>Clave <strong className='text-danger'>*</strong> </label>
             <input 
             type='password' 
             name='clave' 
             required
             className='form-control' 
             placeholder='Clave'
             onChange={ handleInputChange }
             />
           </div>
           <div className='form-group mb-4'>
           <input
           onChange={ (e) => {handleInputChange(e), setIsCoordenadas(e.target.checked)}}
           className='form-check-input'
           type='checkbox'
           value='' 
           name='isCoordenadas'
           id='flexCheckDefault'/>
            <label className="form-check-label" htmlFor="flexCheckDefault">
            &nbsp;&nbsp;Tarjeta de coordenadas
            </label>
           </div>
           {isCoordenadas && 
           <>
           <div className='card text-white' style={{backgroundColor: '#027ADE'}}>
              
             <div className='card-body'>
                <table className='table'>
                  <thead>
                    <tr className='text-center text-white'>
                    <td></td>
                    <td>A</td>
                    <td>B</td>
                    <td>C</td>
                    <td>D</td>
                    <td>E</td>
                    <td>F</td>
                    <td>G</td>
                    <td>H</td>
                    <td>I</td>
                    <td>J</td>
                    </tr>                   
                  </thead>
                  <tbody className='text-white'>
                    <tr>
                      <td>1</td>
                      <td><input type='text' maxLength={2} required name='A1'  className='form-control'  placeholder='A1' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") } } /></td>
                      <td><input type='text' maxLength={2} required name='B1'  className='form-control'  placeholder='B1' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='C1'  className='form-control'  placeholder='C1' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='D1'  className='form-control'  placeholder='D1' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='E1'  className='form-control'  placeholder='E1' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='F1'  className='form-control'  placeholder='F1' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='G1'  className='form-control'  placeholder='G1' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='H1'  className='form-control'  placeholder='H1' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='I1'  className='form-control'  placeholder='I1' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='J1'  className='form-control'  placeholder='J1' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td><input type='text' maxLength={2} required name='A2'  className='form-control'  placeholder='A2' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='B2'  className='form-control'  placeholder='B2' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='C2'  className='form-control'  placeholder='C2' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='D2'  className='form-control'  placeholder='D2' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='E2'  className='form-control'  placeholder='E2' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='F2'  className='form-control'  placeholder='F2' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='G2'  className='form-control'  placeholder='G2' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='H2'  className='form-control'  placeholder='H2' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='I2'  className='form-control'  placeholder='I2' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='J2'  className='form-control'  placeholder='J2' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td><input type='text' maxLength={2} required name='A3'  className='form-control'  placeholder='A3' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='B3'  className='form-control'  placeholder='B3' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='C3'  className='form-control'  placeholder='C3' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='D3'  className='form-control'  placeholder='D3' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='E3'  className='form-control'  placeholder='E3' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='F3'  className='form-control'  placeholder='F3' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='G3'  className='form-control'  placeholder='G3' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='H3'  className='form-control'  placeholder='H3' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='I3'  className='form-control'  placeholder='I3' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='J3'  className='form-control'  placeholder='J3' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td><input type='text' maxLength={2} required name='A4'  className='form-control'  placeholder='A4' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='B4'  className='form-control'  placeholder='B4' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='C4'  className='form-control'  placeholder='C4' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='D4'  className='form-control'  placeholder='D4' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='E4'  className='form-control'  placeholder='E4' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='F4'  className='form-control'  placeholder='F4' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='G4'  className='form-control'  placeholder='G4' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='H4'  className='form-control'  placeholder='H4' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='I4'  className='form-control'  placeholder='I4' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='J4'  className='form-control'  placeholder='J4' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td><input type='text' maxLength={2} required name='A5'  className='form-control'  placeholder='A5' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='B5'  className='form-control'  placeholder='B5' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='C5'  className='form-control'  placeholder='C5' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='D5'  className='form-control'  placeholder='D5' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='E5'  className='form-control'  placeholder='E5' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='F5'  className='form-control'  placeholder='F5' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='G5'  className='form-control'  placeholder='G5' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='H5'  className='form-control'  placeholder='H5' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='I5'  className='form-control'  placeholder='I5' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                      <td><input type='text' maxLength={2} required name='J5'  className='form-control'  placeholder='J5' onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") }  } /></td>
                    </tr>
                  </tbody>
                </table>
             </div>
           </div>
           <div className='form-group mb-4 mt-3'>
           <label>Número tarjeta de Coordenadas <strong className='text-danger'>*</strong> </label>
             <input 
             type='text' 
             name='tarjeta' 
             required
             className='form-control' 
             placeholder='123456789'
             onChange={ (e) => { handleInputChange(e), e.target.value = e.target.value.replace(/[^A-Z\d-]/g, "") } }
             />
           </div>
           </>
          }
           <button 
             type='submit' 
             className='form-control btn btn-primary mt-5'                
             onSubmit={ guardarSecreto } >
                 Guardar Secreto
             </button>
       </>
      );
}
 
export default TarjetaCredito;