import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import axios from 'axios';
import Logout from '../../utils/Logout';
import api from '../../utils/api/api';

const FormCambiarContrasena = () => {
    let navigate = useNavigate();

    const [datos, setDatos] = useState({
        contrasenaActual: '',
        contrasenaNueva: '',
        contrasenaNuevaRep: ''
    })

    const handleInputChange = (e) => {
        setDatos({
            ...datos,
            [e.target.name]: e.target.value
        });
    }

    const guardarCambios = async (e) => {
        e.preventDefault();
        if (datos.contrasenaActual == '' || datos.contrasenaNueva == '' || datos.contrasenaNuevaRep == '') {
            Swal.fire({
                icon: 'warning',
                title: 'Todos los campos son obligatorios',
                text: 'Debes ingresar tu contraseña actual y la nueva para poder realizar el cambio'
            });
            return;
        } else if (datos.contrasenaNueva !== datos.contrasenaNuevaRep) {
            Swal.fire({
                icon: 'warning',
                title: 'Las contraseñas no coinciden',
                text: 'La contraseña nueva no coincide con la repetición de la contraseña nueva'
            });
            return;
        } else {
            const id = localStorage.getItem('id');

            const contrasenaResponse = await api.post(`users/cambiar-contrasena/cliente/${id}`, {
                contrasenaActual: datos.contrasenaActual,
                contrasenaNueva: datos.contrasenaNueva
            }, {
                headers: {
                    'access-token': localStorage.getItem('token')
                }
            }).catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'La contraseña actual no es la misma',
                })
            })

            if (contrasenaResponse != undefined) {
                if (contrasenaResponse.status == 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Las contraseña fue cambiada correctamente',
                        text: 'Inicia sesión con tu nueva contraseña'
                    }).finally(() => {
                        e.target.reset();
                        setDatos({
                            contrasenaActual: '',
                            contrasenaNueva: '',
                            contrasenaNuevaRep: ''
                        })
                        Logout();
                        navigate('/');
                    })
                }
            }
        }
    }

    return (
        <div className='card border-0' >
            <div className='card-title bg-primary text-white' >
                <h4 className='text-center mt-3'>Cambiar contraseña</h4>
            </div>

            <div className='card-body'>

                <form onSubmit={guardarCambios}>
                    <span className='mb-2'> Contraseña actual <strong className='text-danger'>*</strong> </span>
                    <div className='form-group mb-4'>

                        <input
                            type='password'
                            name='contrasenaActual'
                            className='form-control'
                            placeholder='Ingrese la contraseña actual de su cuenta'
                            onChange={handleInputChange}
                        />

                    </div>
                    <div className='form-group mb-4'>
                        <label> Contraseña nueva <strong className='text-danger'>*</strong> </label>
                        <input
                            type='password'
                            name='contrasenaNueva'
                            className='form-control'
                            placeholder='Ingresa la contraseña nueva'
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='form-group mb-4'>
                        <label> Repite la contraseña nueva <strong className='text-danger'>*</strong> </label>
                        <input
                            type='password'
                            name='contrasenaNuevaRep'
                            className='form-control'
                            placeholder='Repite la contraseña nueva ingresada'
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className='form-group mb-5 mt-5'>
                        <button
                            type='submit'
                            className='form-control btn btn-primary mt-5'
                            onSubmit={guardarCambios} >
                            Guardar cambios
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default FormCambiarContrasena;