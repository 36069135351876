import React,  { createContext, useEffect, useState } from 'react';

//crear context
 export const UpdateSecretoContext = createContext();


 //crear el provider

 const UpdateSecretoProvider = (props) => {

    //crear state del context

    const [id, setId] = useState(null);

    useEffect(()=> {
      
    },[id]);


    return (
        <UpdateSecretoContext.Provider value = {{id,setId}}>
            {props.children}
        </UpdateSecretoContext.Provider>
    )
   

 }

 export default UpdateSecretoProvider;

 


 