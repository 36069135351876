
import React, { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2'
import api from '../../utils/api/api';
import { UpdateSecretoContext } from '../../utils/context/Secreto';
import Banca from './Banca';
import ClaveUnica from './ClaveUnica';
import Coordenadas from './Coordenadas';
import TarjetaCredito from './TarjetaCredito';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import UsuarioClave from './UsuarioClave'



const FormEditarSecreto = () => {



  const { id, setId } = useContext(UpdateSecretoContext);

  useEffect(async () => {
    if (id != null) {


      const secreto = await api.get(`secretos/obtener/secreto/${id.id}/${id.tipo}`, {
        headers: {
          'access-token': localStorage.getItem('token')
        }
      });




      if (id.tipo == 1) {
        setDatos({
          id: secreto.data.solicitud[0].dats_aws_secret_name,
          nombre: secreto.data.solicitud[0].dats_nombre,
          descripcion: secreto.data.solicitud[0].dats_nombre,
          rut: secreto.data.dato.rut,
          password: secreto.data.dato.claveUnica,
          tipo: '1',
          confirmar: '',
          number: '',
          name: '',
          cvc: '',
          expiry: '',
          focus: '',
          usuario: '',
          contrasena: '',
          confirmarContrasena: ''
        });
      } else if (id.tipo == 2) {
        setDatos({
          id: secreto.data.solicitud[0].dats_aws_secret_name,
          nombre: secreto.data.solicitud[0].dats_nombre,
          descripcion: secreto.data.solicitud[0].dats_nombre,
          rut: secreto.data.dato.rut,
          password: secreto.data.dato.clave,
          tipo: '2',
          cuenta: secreto.data.dato.cuenta,
          tipoCuenta: secreto.data.dato.tipoCuenta,
          banco: secreto.data.dato.banco,
          number: '',
          name: '',
          cvc: '',
          expiry: '',
          focus: '',
          usuario: '',
          contrasena: '',
          confirmarContrasena: ''
        });
      } else if (id.tipo == 3) {
        setDatos({
          id: secreto.data.solicitud[0].dats_aws_secret_name,
          nombre: secreto.data.solicitud[0].dats_nombre,
          descripcion: secreto.data.solicitud[0].dats_nombre,
          rut: secreto.data.dato.rut,
          password: secreto.data.dato.claveUnica,
          banco: secreto.data.dato.banco,
          tipo: '3',
          confirmar: '',
          tarjeta: secreto.data.dato.tarjeta,
          A1: secreto.data.dato.coordenadas.A1,
          A2: secreto.data.dato.coordenadas.A2,
          A3: secreto.data.dato.coordenadas.A3,
          A4: secreto.data.dato.coordenadas.A4,
          A5: secreto.data.dato.coordenadas.A5,
          B1: secreto.data.dato.coordenadas.B1,
          B2: secreto.data.dato.coordenadas.B2,
          B3: secreto.data.dato.coordenadas.B3,
          B4: secreto.data.dato.coordenadas.B4,
          B5: secreto.data.dato.coordenadas.B5,
          C1: secreto.data.dato.coordenadas.C1,
          C2: secreto.data.dato.coordenadas.C2,
          C3: secreto.data.dato.coordenadas.C3,
          C4: secreto.data.dato.coordenadas.C4,
          C5: secreto.data.dato.coordenadas.C5,
          D1: secreto.data.dato.coordenadas.D1,
          D2: secreto.data.dato.coordenadas.D2,
          D3: secreto.data.dato.coordenadas.D3,
          D4: secreto.data.dato.coordenadas.D4,
          D5: secreto.data.dato.coordenadas.D5,
          E1: secreto.data.dato.coordenadas.E1,
          E2: secreto.data.dato.coordenadas.E2,
          E3: secreto.data.dato.coordenadas.E3,
          E4: secreto.data.dato.coordenadas.E4,
          E5: secreto.data.dato.coordenadas.E5,
          F1: secreto.data.dato.coordenadas.F1,
          F2: secreto.data.dato.coordenadas.F2,
          F3: secreto.data.dato.coordenadas.F3,
          F4: secreto.data.dato.coordenadas.F4,
          F5: secreto.data.dato.coordenadas.F5,
          G1: secreto.data.dato.coordenadas.G1,
          G2: secreto.data.dato.coordenadas.G2,
          G3: secreto.data.dato.coordenadas.G3,
          G4: secreto.data.dato.coordenadas.G4,
          G5: secreto.data.dato.coordenadas.G5,
          H1: secreto.data.dato.coordenadas.H1,
          H2: secreto.data.dato.coordenadas.H2,
          H3: secreto.data.dato.coordenadas.H3,
          H4: secreto.data.dato.coordenadas.H4,
          H5: secreto.data.dato.coordenadas.H5,
          I1: secreto.data.dato.coordenadas.I1,
          I2: secreto.data.dato.coordenadas.I2,
          I3: secreto.data.dato.coordenadas.I3,
          I4: secreto.data.dato.coordenadas.I4,
          I5: secreto.data.dato.coordenadas.I5,
          J1: secreto.data.dato.coordenadas.J1,
          J2: secreto.data.dato.coordenadas.J2,
          J3: secreto.data.dato.coordenadas.J3,
          J4: secreto.data.dato.coordenadas.J4,
          J5: secreto.data.dato.coordenadas.J5,
          number: '',
          name: '',
          cvc: '',
          expiry: '',
          focus: '',
          usuario: '',
          contrasena: '',
          confirmarContrasena: ''
        });
      } else if (id.tipo == 4) {
        const cuenta = secreto.data.dato.cuenta.split('_')
        if (secreto.data.dato.coordenadas == null) {
          setDatos({
            id: secreto.data.solicitud[0].dats_aws_secret_name,
            nombre: secreto.data.solicitud[0].dats_nombre,
            descripcion: secreto.data.solicitud[0].dats_nombre,
            rut: secreto.data.dato.rut,
            password: secreto.data.dato.password,
            tipo: '4',
            number: cuenta[0],
            name: secreto.data.dato.name,
            expiry: cuenta[2],
            cvc: cuenta[1],
            banco: secreto.data.dato.banco,
            coordenadas: secreto.data.dato.coordenadas,
            isCoordenadas: false,
            usuario: '',
            contrasena: '',
            confirmarContrasena: ''
          })
        } else {
          setDatos({
            id: secreto.data.solicitud[0].dats_aws_secret_name,
            nombre: secreto.data.solicitud[0].dats_nombre,
            descripcion: secreto.data.solicitud[0].dats_nombre,
            rut: secreto.data.dato.rut,
            password: secreto.data.dato.password,
            tipo: '4',
            number: cuenta[0],
            name: secreto.data.dato.name,
            expiry: cuenta[2],
            cvc: cuenta[1],
            banco: secreto.data.dato.banco,
            coordenadas: secreto.data.dato.coordenadas,
            numeroTarjeta: secreto.data.dato.numeroTarjeta,
            A1: secreto.data.dato.coordenadas.A1,
            A2: secreto.data.dato.coordenadas.A2,
            A3: secreto.data.dato.coordenadas.A3,
            A4: secreto.data.dato.coordenadas.A4,
            A5: secreto.data.dato.coordenadas.A5,
            B1: secreto.data.dato.coordenadas.B1,
            B2: secreto.data.dato.coordenadas.B2,
            B3: secreto.data.dato.coordenadas.B3,
            B4: secreto.data.dato.coordenadas.B4,
            B5: secreto.data.dato.coordenadas.B5,
            C1: secreto.data.dato.coordenadas.C1,
            C2: secreto.data.dato.coordenadas.C2,
            C3: secreto.data.dato.coordenadas.C3,
            C4: secreto.data.dato.coordenadas.C4,
            C5: secreto.data.dato.coordenadas.C5,
            D1: secreto.data.dato.coordenadas.D1,
            D2: secreto.data.dato.coordenadas.D2,
            D3: secreto.data.dato.coordenadas.D3,
            D4: secreto.data.dato.coordenadas.D4,
            D5: secreto.data.dato.coordenadas.D5,
            E1: secreto.data.dato.coordenadas.E1,
            E2: secreto.data.dato.coordenadas.E2,
            E3: secreto.data.dato.coordenadas.E3,
            E4: secreto.data.dato.coordenadas.E4,
            E5: secreto.data.dato.coordenadas.E5,
            F1: secreto.data.dato.coordenadas.F1,
            F2: secreto.data.dato.coordenadas.F2,
            F3: secreto.data.dato.coordenadas.F3,
            F4: secreto.data.dato.coordenadas.F4,
            F5: secreto.data.dato.coordenadas.F5,
            G1: secreto.data.dato.coordenadas.G1,
            G2: secreto.data.dato.coordenadas.G2,
            G3: secreto.data.dato.coordenadas.G3,
            G4: secreto.data.dato.coordenadas.G4,
            G5: secreto.data.dato.coordenadas.G5,
            H1: secreto.data.dato.coordenadas.H1,
            H2: secreto.data.dato.coordenadas.H2,
            H3: secreto.data.dato.coordenadas.H3,
            H4: secreto.data.dato.coordenadas.H4,
            H5: secreto.data.dato.coordenadas.H5,
            I1: secreto.data.dato.coordenadas.I1,
            I2: secreto.data.dato.coordenadas.I2,
            I3: secreto.data.dato.coordenadas.I3,
            I4: secreto.data.dato.coordenadas.I4,
            I5: secreto.data.dato.coordenadas.I5,
            J1: secreto.data.dato.coordenadas.J1,
            J2: secreto.data.dato.coordenadas.J2,
            J3: secreto.data.dato.coordenadas.J3,
            J4: secreto.data.dato.coordenadas.J4,
            J5: secreto.data.dato.coordenadas.J5,
            isCoordenadas: true,
            usuario: '',
            contrasena: '',
            confirmarContrasena: ''
          })
        }

        ;
      } else if (id.tipo == 5) {
        setDatos({
          id: secreto.data.solicitud[0].dats_aws_secret_name,
          nombre: secreto.data.solicitud[0].dats_nombre,
          descripcion: secreto.data.solicitud[0].dats_nombre,
          rut: secreto.data.dato.rut,
          usuario: secreto.data.dato.usuario,
          contrasena: secreto.data.dato.contrasena,
          confirmarContrasena: '',
          tipo: '5',
          number: '',
          name: '',
          cvc: '',
          expiry: '',
          focus: ''
        });
      }

    }

  }, [id])

  const [disabledButtom, setDisabledButtom] = useState(true);

  const [datos, setDatos] = useState({
    id: '',
    nombre: '',
    descripcion: '',
    rut: '',
    password: '',
    tipo: '',
    confirmar: '',
    A1: '',
    A2: '',
    A3: '',
    A4: '',
    A5: '',
    B1: '',
    B2: '',
    B3: '',
    B4: '',
    B5: '',
    C1: '',
    C2: '',
    C3: '',
    C4: '',
    C5: '',
    D1: '',
    D2: '',
    D3: '',
    D4: '',
    D5: '',
    E1: '',
    E2: '',
    E3: '',
    E4: '',
    E5: '',
    F1: '',
    F2: '',
    F3: '',
    F4: '',
    F5: '',
    G1: '',
    G2: '',
    G3: '',
    G4: '',
    G5: '',
    H1: '',
    H2: '',
    H3: '',
    H4: '',
    H5: '',
    I1: '',
    I2: '',
    I3: '',
    I4: '',
    I5: '',
    J1: '',
    J2: '',
    J3: '',
    J4: '',
    J5: '',
    cuenta: '',
    tipoCuenta: '',
    banco: '',
    number: '',
    name: '',
    cvc: '',
    expiry: '',
    focus: '',
    isCoordenadas: false,
    usuario: '',
    contrasena: '',
    confirmarContrasena: '',
  });

  useEffect(() => {
    if (datos.tipo === '5') {
      if (datos.contrasena == datos.confirmarContrasena && datos.contrasena !== '' && datos.confirmarContrasena !== '') {
        setDisabledButtom(false);
      } else {
        setDisabledButtom(true);
      }
    }
  }, [datos])

  const handleInputChange = (e) => {

    if (e.target.name != 'isCoordenadas') {
      setDatos({
        ...datos,
        [e.target.name]: e.target.value
      });
    } else {
      setDatos({
        ...datos,
        [e.target.name]: e.target.checked
      });
    }
  }

  const handleFocusChange = (e) => {
    setDatos({
      ...datos,
      focus: e.target.name
    });
  }

  const handleFocusOutChange = (e) => {
    setDatos({
      ...datos,
      focus: 'name'
    });
  }

  const formCambios = (tipo) => {

    switch (tipo) {

      case '1':
        return <ClaveUnica datos={datos} setDatos={setDatos} handleInputChange={handleInputChange} editarSecreto={editarSecreto} />


      case '2':
        return <Banca datos={datos} setDatos={setDatos} handleInputChange={handleInputChange} editarSecreto={editarSecreto} />

      case '3':
        return <Coordenadas datos={datos} setDatos={setDatos} handleInputChange={handleInputChange} editarSecreto={editarSecreto} />

      case '4': //El 3 es la tarjeta de coordenadas{}
        return <>
          <TarjetaCredito handleInputChange={handleInputChange} handleFocusChange={handleFocusChange} datos={datos} handleFocusOutChange={handleFocusOutChange} editarSecreto={editarSecreto}><Cards
            cvc={datos.cvc}
            expiry={datos.expiry}
            focused={datos.focus}
            name={datos.name}
            number={datos.number}
            placeholders={{ name: '' }}
          /></TarjetaCredito></>

      case '5':
        return <UsuarioClave datos={datos} setDatos={setDatos} handleInputChange={handleInputChange} editarSecreto={editarSecreto} setDisabled={disabledButtom} />

      default:
        return <p className='strong text-danger'><strong>* </strong>Selecciona un tipo de dato secreto para continuar</p>

    }
  }



  const editarSecreto = async (e) => {
    e.preventDefault();


    try {
      let secreto = '';
      if (id.tipo == 1) {
        secreto = await api.post(`secretos/editar-secreto`, {
          id: datos.id,
          nombre: datos.nombre,
          descripcion: datos.descripcion,
          secretos: {
            rut: datos.rut,
            claveUnica: datos.password
          },
          headers: {
            'access-token': localStorage.getItem('token')
          }
        });
      } else if (id.tipo == 2) {
        secreto = await api.post(`secretos/editar-secreto`, {
          id: datos.id,
          nombre: datos.nombre,
          descripcion: datos.descripcion,
          secretos: {
            rut: datos.rut,
            clave: datos.password,
            cuenta: datos.cuenta,
            tipoCuenta: datos.tipoCuenta,
            banco: datos.banco,

          },
          headers: {
            'access-token': localStorage.getItem('token')
          }
        });


      } else if (id.tipo == 3) {
        secreto = await api.post(`secretos/editar-secreto`, {
          id: datos.id,
          nombre: datos.nombre,
          descripcion: datos.descripcion,

          secretos: {
            rut: datos.rut,
            banco: datos.banco,
            tarjeta: datos.tarjeta,
            coordenadas: {
              A1: datos.A1,
              A2: datos.A2,
              A3: datos.A3,
              A4: datos.A4,
              A5: datos.A5,
              B1: datos.B1,
              B2: datos.B2,
              B3: datos.B3,
              B4: datos.B4,
              B5: datos.B5,
              C1: datos.C1,
              C2: datos.C2,
              C3: datos.C3,
              C4: datos.C4,
              C5: datos.C5,
              D1: datos.D1,
              D2: datos.D2,
              D3: datos.D3,
              D4: datos.D4,
              D5: datos.D5,
              E1: datos.E1,
              E2: datos.E2,
              E3: datos.E3,
              E4: datos.E4,
              E5: datos.E5,
              F1: datos.F1,
              F2: datos.F2,
              F3: datos.F3,
              F4: datos.F4,
              F5: datos.F5,
              G1: datos.G1,
              G2: datos.G2,
              G3: datos.G3,
              G4: datos.G4,
              G5: datos.G5,
              H1: datos.H1,
              H2: datos.H2,
              H3: datos.H3,
              H4: datos.H4,
              H5: datos.H5,
              I1: datos.I1,
              I2: datos.I2,
              I3: datos.I3,
              I4: datos.I4,
              I5: datos.I5,
              J1: datos.J1,
              J2: datos.J2,
              J3: datos.J3,
              J4: datos.J4,
              J5: datos.J5,
            }

          },
          headers: {
            'access-token': localStorage.getItem('token')
          }
        });
      } else if (id.tipo == 4) {
        const number = (datos.number).replace(/ /g, "");
        const expiry = (datos.expiry).replace(/\//g, "");
        const cvc = datos.cvc;

        const formatoCuenta = `${number}_${cvc}_${expiry}`

        if (!datos.isCoordenadas) {
          secreto = await api.post(`secretos/editar-secreto`, {
            id: datos.id,
            nombre: datos.nombre,
            descripcion: datos.descripcion,
            secretos: {
              rut: datos.rut,
              banco: datos.banco,
              password: datos.password,
              cuenta: formatoCuenta,
              name: datos.name,
              coordenadas: null
            }
          }, {
            headers: {
              'access-token': localStorage.getItem('token')
            }
          });
        } else {
          secreto = await api.post(`secretos/editar-secreto`, {
            id: datos.id,
            nombre: datos.nombre,
            descripcion: datos.descripcion,
            secretos: {
              rut: datos.rut,
              banco: datos.banco,
              password: datos.password,
              cuenta: formatoCuenta,
              name: datos.name,
              numeroTarjeta: datos.numeroTarjeta,
              coordenadas: {
                A1: datos.A1,
                A2: datos.A2,
                A3: datos.A3,
                A4: datos.A4,
                A5: datos.A5,
                B1: datos.B1,
                B2: datos.B2,
                B3: datos.B3,
                B4: datos.B4,
                B5: datos.B5,
                C1: datos.C1,
                C2: datos.C2,
                C3: datos.C3,
                C4: datos.C4,
                C5: datos.C5,
                D1: datos.D1,
                D2: datos.D2,
                D3: datos.D3,
                D4: datos.D4,
                D5: datos.D5,
                E1: datos.E1,
                E2: datos.E2,
                E3: datos.E3,
                E4: datos.E4,
                E5: datos.E5,
                F1: datos.F1,
                F2: datos.F2,
                F3: datos.F3,
                F4: datos.F4,
                F5: datos.F5,
                G1: datos.G1,
                G2: datos.G2,
                G3: datos.G3,
                G4: datos.G4,
                G5: datos.G5,
                H1: datos.H1,
                H2: datos.H2,
                H3: datos.H3,
                H4: datos.H4,
                H5: datos.H5,
                I1: datos.I1,
                I2: datos.I2,
                I3: datos.I3,
                I4: datos.I4,
                I5: datos.I5,
                J1: datos.J1,
                J2: datos.J2,
                J3: datos.J3,
                J4: datos.J4,
                J5: datos.J5
              }
            }
          }, {
            headers: {
              'access-token': localStorage.getItem('token')
            }
          });
        }



      } else if (id.tipo == 5) {

        secreto = await api.post(`secretos/editar-secreto`, {
          id: datos.id,
          nombre: datos.nombre,
          descripcion: datos.descripcion,
          secretos: {
            rut: datos.rut,
            usuario: datos.usuario,
            contrasena: datos.contrasena
          },
          headers: {
            'access-token': localStorage.getItem('token')
          }
        });
      }






      if (secreto.data) {
        Swal.fire(
          'Buenas noticias',
          secreto.data,
          'success'
        );

        setId(null);

        setDatos({
          id: '',
          nombre: '',
          descripcion: '',
          rut: '',
          password: '',
          tipo: '',
          confirmar: '',
          cuenta: '',
          tipoCuenta: '',
          banco: '',
          tarjeta: '',
          A1: '',
          A2: '',
          A3: '',
          A4: '',
          A5: '',
          B1: '',
          B2: '',
          B3: '',
          B4: '',
          B5: '',
          C1: '',
          C2: '',
          C3: '',
          C4: '',
          C5: '',
          D1: '',
          D2: '',
          D3: '',
          D4: '',
          D5: '',
          E1: '',
          E2: '',
          E3: '',
          E4: '',
          E5: '',
          F1: '',
          F2: '',
          F3: '',
          F4: '',
          F5: '',
          G1: '',
          G2: '',
          G3: '',
          G4: '',
          G5: '',
          H1: '',
          H2: '',
          H3: '',
          H4: '',
          H5: '',
          I1: '',
          I2: '',
          I3: '',
          I4: '',
          I5: '',
          J1: '',
          J2: '',
          J3: '',
          J4: '',
          J5: '',
          banco: '',
          number: '',
          name: '',
          cvc: '',
          expiry: '',
          focus: '',
          usuario: '',
          contrasena: '',
          confirmarContrasena: ''
        });
      } else {
        Swal.fire(
          'Ops...',
          'En estos momentos no podemos procesar tu solicitud.',
          'error'
        )
      }

    } catch (e) {
      console.log(e);
    }
  }



  return (
    <>
      <div className='card  ' >
        <div className='card-title bg-primary text-white' >
          <h4 className='text-center mt-3'>Modificar datos secretos</h4>

        </div>
        <div className='card-body'>

          <form onSubmit={editarSecreto}>

            {id != null ? formCambios(id.tipo.toString()) : <div className='text-center bg-primary text-white p-2'><h5>Debes seleccionar un secreto para poder editar</h5></div>}


          </form>
        </div>
      </div>
    </>
  )

}

export default FormEditarSecreto;
