import axios from 'axios';
import React, { useState, useEffect } from 'react';
import ValidarRut from '../../utils/ValidarRut';
import Swal from 'sweetalert2'
import ClaveUnica from './ClaveUnica';
import CuentaBancaria from './CuentaBancaria';
import api from '../../utils/api/api';
import TarjetaCredito from './TarjetaCredito';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import UsuarioClave from './UsuarioClave';


const FormSecreto = () => {


  const [datos, setDatos] = useState({
    nombre: '',
    apellidos: '',
    cuenta: '',
    tipoCuenta: '',
    banco: '',
    tarjeta: '',
    propietario: '',
    rutProp: '',
    rut: '',
    clave: '',
    tipo: '',
    confirmar: '',
    A1: '',
    A2: '',
    A3: '',
    A4: '',
    A5: '',
    B1: '',
    B2: '',
    B3: '',
    B4: '',
    B5: '',
    C1: '',
    C2: '',
    C3: '',
    C4: '',
    C5: '',
    D1: '',
    D2: '',
    D3: '',
    D4: '',
    D5: '',
    E1: '',
    E2: '',
    E3: '',
    E4: '',
    E5: '',
    F1: '',
    F2: '',
    F3: '',
    F4: '',
    F5: '',
    G1: '',
    G2: '',
    G3: '',
    G4: '',
    G5: '',
    H1: '',
    H2: '',
    H3: '',
    H4: '',
    H5: '',
    I1: '',
    I2: '',
    I3: '',
    I4: '',
    I5: '',
    J1: '',
    J2: '',
    J3: '',
    J4: '',
    J5: '',
    number: '',
    name: '',
    cvc: '',
    expiry: '',
    focus: '',
    isCoordenadas: false,
    contrasena: '',
    usuario: ''
  });
  const [contrato, setContrato] = useState([]);
  const obtenerContratos = async () => {
    try {
      const id = localStorage.getItem('id');
      const contratos = await axios.get(`https://sgds-api.orbisdata.cl/v1/servicios/contrato/${id}`, {
        headers: {
          'access-token': localStorage.getItem('token')
        }


      });

      setContrato(contratos.data);

    } catch (e) {
      console.log(e);
    }

  }

  useEffect(() => {
    obtenerContratos();
  }, []);

  const handleInputChange = (e) => {

    if (e.target.name != 'isCoordenadas') {
      setDatos({
        ...datos,
        [e.target.name]: e.target.value
      });
    } else {
      setDatos({
        ...datos,
        [e.target.name]: e.target.checked
      });
    }
  }

  const handleFocusChange = (e) => {
    setDatos({
      ...datos,
      focus: e.target.name
    });
  }

  const handleFocusOutChange = (e) => {
    setDatos({
      ...datos,
      focus: 'name'
    });
  }

  const formCambios = (tipo) => {

    switch (tipo) {

      case '1':
        return <ClaveUnica handleInputChange={handleInputChange} guardarSecreto={guardarSecreto} />


      case '2':
        return <CuentaBancaria handleInputChange={handleInputChange} guardarSecreto={guardarSecreto} />

      case '4': //El 3 es la tarjeta de coordenadas
        return <>
          <TarjetaCredito handleInputChange={handleInputChange} handleFocusChange={handleFocusChange} handleFocusOutChange={handleFocusOutChange} guardarSecreto={guardarSecreto}><Cards
            cvc={datos.cvc}
            expiry={datos.expiry}
            focused={datos.focus}
            name={datos.name}
            number={datos.number}
            placeholders={{ name: '' }}
          /></TarjetaCredito></>
      case '5':
        return <UsuarioClave handleInputChange={handleInputChange} guardarSecreto={guardarSecreto} />

      default:
        return <p className='strong text-danger'><strong>* </strong>Selecciona un tipo de dato secreto para continuar</p>

    }
  }

  const guardarSecreto = async (e) => {
    let insertarCoordenadas = '';
    let mensajeCoordenadas = '';

    e.preventDefault();
    
    if (datos.tipo != '' && datos.apellidos != '' && datos.nombre != '' && datos.rutProp != '') {
      
      if (ValidarRut(datos.rutProp) != true) {
        Swal.fire({
          icon: 'warning',
          title: 'Rut invalido',
          text: 'Recuerda que el formato debe ser 11111111-1',
        });
        
        return;
      }
      
      if (datos.confirmar != datos.clave && datos.tipo == '1') {
        Swal.fire({
          icon: 'warning',
          title: 'Las contraseñas no coinciden',
          text: 'Recuerda ingresar la misma contraseña en ambos campos',
        });

        return;
      }
      if (datos.contrasena != datos.confirmarContrasena && datos.tipo == '5') {
        Swal.fire({
          icon: 'warning',
          title: 'Las contraseñas no coinciden',
          text: 'Recuerda ingresar la misma contraseña en ambos campos',
        });
        
        return;
      }
      

      const propietarioSecreto = await api.post('users/propietario', {
        nombre: datos.nombre,
        apellidos: datos.apellidos,
        rut: datos.rutProp.substr(0, datos.rutProp.length - 2),
        dv: datos.rutProp.slice(-1)
      }, {
        headers: {
          'access-token': localStorage.getItem('token')
        }
      });



      if (propietarioSecreto.status == 200 || propietarioSecreto.status == 201) {
        let data = {};
        let dataCoordenada = {};

        switch (datos.tipo) {
          case '1':

            data = {
              nombre: datos.rutProp + "claveUnica",
              descripcion: "Registro de clave única",
              tipo: datos.tipo,
              propietario: propietarioSecreto.data.propietario || propietarioSecreto.data.result.prop_id,
              secret: {
                rut: datos.rutProp,
                claveUnica: datos.clave
              }
            }

            break;

          case '2':

            data = {
              nombre: datos.rutProp + "CuentaBancaria",
              descripcion: "Registro de cuenta bancaria",
              tipo: datos.tipo,
              propietario: propietarioSecreto.data.propietario || propietarioSecreto.data.result.prop_id,
              secret: {
                rut: datos.rutProp,
                cuenta: datos.cuenta,
                tipoCuenta: datos.tipoCuenta,
                banco: datos.banco,
                clave: datos.clave
              }
            }

            dataCoordenada = {
              nombre: datos.rutProp + "Coordenadas",
              descripcion: "Registro de tarjeta de coordenadas",
              tipo: '3',
              propietario: propietarioSecreto.data.propietario || propietarioSecreto.data.result.prop_id,
              secret: {
                rut: datos.rutProp,
                banco: datos.banco,
                tarjeta: datos.tarjeta,
                coordenadas: {
                  A1: datos.A1,
                  A2: datos.A2,
                  A3: datos.A3,
                  A4: datos.A4,
                  A5: datos.A5,
                  B1: datos.B1,
                  B2: datos.B2,
                  B3: datos.B3,
                  B4: datos.B4,
                  B5: datos.B5,
                  C1: datos.C1,
                  C2: datos.C2,
                  C3: datos.C3,
                  C4: datos.C4,
                  C5: datos.C5,
                  D1: datos.D1,
                  D2: datos.D2,
                  D3: datos.D3,
                  D4: datos.D4,
                  D5: datos.D5,
                  E1: datos.E1,
                  E2: datos.E2,
                  E3: datos.E3,
                  E4: datos.E4,
                  E5: datos.E5,
                  F1: datos.F1,
                  F2: datos.F2,
                  F3: datos.F3,
                  F4: datos.F4,
                  F5: datos.F5,
                  G1: datos.G1,
                  G2: datos.G2,
                  G3: datos.G3,
                  G4: datos.G4,
                  G5: datos.G5,
                  H1: datos.H1,
                  H2: datos.H2,
                  H3: datos.H3,
                  H4: datos.H4,
                  H5: datos.H5,
                  I1: datos.I1,
                  I2: datos.I2,
                  I3: datos.I3,
                  I4: datos.I4,
                  I5: datos.I5,
                  J1: datos.J1,
                  J2: datos.J2,
                  J3: datos.J3,
                  J4: datos.J4,
                  J5: datos.J5
                }
              }
            }

            const secretoCoordenadas = await api.post('secretos/crear-secreto', {
              dataCoordenada
            }, {
              headers: {
                'access-token': localStorage.getItem('token')
              }
            });


            const contProp = await api.post('servicios/permiso', {
              propietario: propietarioSecreto.data.propietario || propietarioSecreto.data.result.prop_id,
              contrato: datos.contrato
            }, {
              headers: {
                'access-token': localStorage.getItem('token')
              }
            });

            insertarCoordenadas = secretoCoordenadas;



            break;

          case '4':
            const number = (datos.number).replace(/ /g, "");
            const expiry = (datos.expiry).replace(/\//g, "");
            const cvv = datos.cvc;
            const formatoCuenta = `${number}_${cvv}_${expiry}`;

            if (!datos.isCoordenadas) {
              data = {
                nombre: datos.rutProp + "TC" + Math.floor(Math.random() * 9999) + (datos.banco).replace(/ /g, ""),
                descripcion: "Registro de tarjeta de crédito",
                tipo: datos.tipo,
                propietario: propietarioSecreto.data.propietario || propietarioSecreto.data.result.prop_id,
                secret: {
                  rut: datos.rutProp,
                  banco: datos.banco,
                  password: datos.clave,
                  cuenta: formatoCuenta,
                  name: `${datos.nombre} ${datos.apellidos}`,
                  coordenadas: null
                }
              }
            } else {
              data = {
                nombre: datos.rutProp + "TC" + Math.floor(Math.random() * 9999) + (datos.banco).replace(/ /g, ""),
                descripcion: "Registro de tarjeta de crédito",
                tipo: datos.tipo,
                propietario: propietarioSecreto.data.propietario || propietarioSecreto.data.result.prop_id,
                secret: {
                  rut: datos.rutProp,
                  banco: datos.banco,
                  password: datos.clave,
                  cuenta: formatoCuenta,
                  name: `${datos.nombre} ${datos.apellidos}`,
                  numeroTarjeta: datos.tarjeta,
                  coordenadas: {
                    A1: datos.A1,
                    A2: datos.A2,
                    A3: datos.A3,
                    A4: datos.A4,
                    A5: datos.A5,
                    B1: datos.B1,
                    B2: datos.B2,
                    B3: datos.B3,
                    B4: datos.B4,
                    B5: datos.B5,
                    C1: datos.C1,
                    C2: datos.C2,
                    C3: datos.C3,
                    C4: datos.C4,
                    C5: datos.C5,
                    D1: datos.D1,
                    D2: datos.D2,
                    D3: datos.D3,
                    D4: datos.D4,
                    D5: datos.D5,
                    E1: datos.E1,
                    E2: datos.E2,
                    E3: datos.E3,
                    E4: datos.E4,
                    E5: datos.E5,
                    F1: datos.F1,
                    F2: datos.F2,
                    F3: datos.F3,
                    F4: datos.F4,
                    F5: datos.F5,
                    G1: datos.G1,
                    G2: datos.G2,
                    G3: datos.G3,
                    G4: datos.G4,
                    G5: datos.G5,
                    H1: datos.H1,
                    H2: datos.H2,
                    H3: datos.H3,
                    H4: datos.H4,
                    H5: datos.H5,
                    I1: datos.I1,
                    I2: datos.I2,
                    I3: datos.I3,
                    I4: datos.I4,
                    I5: datos.I5,
                    J1: datos.J1,
                    J2: datos.J2,
                    J3: datos.J3,
                    J4: datos.J4,
                    J5: datos.J5
                  }
                }
              }
            }
            break;
          case '5':
            data = {
              nombre: datos.rutProp + "UC" + Math.floor(Math.random() * 9999) + (datos.banco).replace(/ /g, ""),
              descripcion: "Registro de usuario y clave",
              tipo: datos.tipo,
              propietario: propietarioSecreto.data.propietario || propietarioSecreto.data.result.prop_id,
              secret: {
                rut: datos.rutProp,
                usuario: datos.usuario,
                contrasena: datos.contrasena
              }
            }
            break;

          default:
            break;
        }

        const secreto = await api.post('secretos/crear-secreto', {
          data
        }, {
          headers: {
            'access-token': localStorage.getItem('token')
          }
        });




        const contProp = await api.post('servicios/permiso', {
          propietario: propietarioSecreto.data.propietario || propietarioSecreto.data.result.prop_id,
          contrato: datos.contrato
        }, {
          headers: {
            'access-token': localStorage.getItem('token')
          }
        });
        ;


        if (secreto.status == 201 || secreto.status == 200) {

          if (insertarCoordenadas.status == 201 || insertarCoordenadas.status == 200 && insertarCoordenadas) {
            mensajeCoordenadas = "y tus coordenadas las obtienes con : " + insertarCoordenadas.data.secreto.dats_id
          } else {
            mensajeCoordenadas = ''
          }


          Swal.fire({
            icon: 'success',
            title: 'Tu secreto se registró correctamente',
            text: 'Para acceder a tu secreto debes hacerlo con el siguiente identificador: ' + secreto.data.secreto.dats_id + " " + mensajeCoordenadas
          });



          e.target.reset();


          setDatos({
            nombre: '',
            apellidos: '',
            rutProp: '',
            rut: '',
            clave: '',
            tipo: '',
            confirmar: '',
          });

        }
      }

    } else {
      if (ValidarRut(datos.rutProp) != true) {
        Swal.fire({
          icon: 'warning',
          title: 'Existen campos vacíos',
          text: 'Recuerda completar todos los campos',
        });
      }
    }
  }


  return (

    <div className='card border-0' >
      <div className='card-title bg-primary text-white' >
        <h4 className='text-center mt-3'>Gestor de datos secretos</h4>

      </div>

      <div className='card-body'>

        <form onSubmit={guardarSecreto}>
          <span className='mb-2'> Rut (11111111-1) <strong className='text-danger'>*</strong> </span>
          <div className='form-group mb-4'>

            <input
              type='text'
              name='rutProp'
              className='form-control'
              placeholder='Ingrese el rut del propietario'
              onChange={handleInputChange}
            />

          </div>
          <div className='form-group mb-4'>
            <label>Nombre <strong className='text-danger'>*</strong> </label>
            <input
              type='text'
              name='nombre'
              className='form-control'
              placeholder='Ingrese el nombre del propietario'
              onChange={handleInputChange}
            />
          </div>
          <div className='form-group mb-4'>
            <label>Apellidos <strong className='text-danger'>*</strong> </label>
            <input
              type='text'
              name='apellidos'
              className='form-control'
              placeholder='Ingrese los apellidos del dueño'
              onChange={handleInputChange}
            />
          </div>
          <div className='form-group mb-3'>
            <label>Tipo de dato secreto <strong className='text-danger'>*</strong> </label>
            <select defaultValue='' name='tipo' className='form-control' onChange={handleInputChange} >
              <option value=""> --Selecciona un valor --</option>
              <option value="1"> Clave única</option>
              <option value="2"> Cuenta Bancaria</option>
              <option value="4"> Tarjeta de crédito</option>
              <option value="5"> Usuario y Clave</option>
            </select>
          </div>
          <div className='form-group mb-3'>
            <label>Contrato<strong className='text-danger'>*</strong> </label>
            <select defaultValue='' name='contrato' className='form-control' onChange={handleInputChange}>
              <option value=""> --Selecciona un valor --</option>
              {
                contrato.length > 0 ? (
                  contrato.map(cont => (
                    <option key={cont.cont_id}
                      value={cont.cont_id}>{cont.cont_nombre}</option>
                  ))
                ) : (
                  null
                )
              }


            </select>
          </div>

          {formCambios(datos.tipo)}

          <div className='form-group mb-5 mt-5'>


          </div>
        </form>
      </div>
    </div>

  );

}

export default FormSecreto;
