import React from 'react';
import { Link } from 'react-router-dom';
import Logout from '../../utils/Logout';
 
const Navbar = () => {
    return ( 
 
     <>
<nav className="navbar navbar-expand-lg navbar-light bg-primary text-white ">
  <div className="container-fluid">
    <a className="navbar-brand text-white " href="#">Gestor de datos secretos</a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse text-white" id="navbarNav">
      <ul className="navbar-nav  ">
        <li className="nav-item  ">
          <Link className="nav-link active text-white" aria-current="page" to="/crear-secreto">Inicio</Link>
        </li>  
        <li className="nav-item  ">
          <Link className="nav-link active text-white" aria-current="page" to="/listar-secretos">Mis secretos</Link>
        </li>
        <li className="nav-item  ">
          <Link className="nav-link active text-white" aria-current="page" to="/cambiar-contrasena">Cambiar contraseña</Link>
        </li>          
      </ul>
    </div>
    <div className='d-flex justify-content-end'>
    <ul className="navbar-nav  ">
        <li className="nav-item  ">
          <button className="nav-link active text-white btn-primary btn" onClick={  Logout } aria-current="page"><Link  style={{ textDecoration: 'none',   color: 'inherit' }} to="/">Cerrar sesión</Link></button>
        </li>         
      </ul>
    </div>
  </div>
</nav>
<svg style={{marginTop:-90, marginBottom:60}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 160"><path fill="#0d6efd" fillOpacity="1" d="M0,96L60,112C120,128,240,160,360,154.7C480,149,600,107,720,101.3C840,96,960,128,1080,144C1200,160,1320,160,1380,160L1440,160L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>

      </> );
}
 
export default Navbar; 
 
  
 