import React from 'react';
import FormSecreto from '../../components/FormSecreto';
import user from '../../assets/images/password.gif'
const CrearSecreto = () => {
    return ( 
 
       <>
  
       <div  className='container'>
        <div className='row'>
            <div className='col-sm-7 '>        
            <FormSecreto/>
            </div>
           <div className='col-sm-5'>
           <img src={user} className='img-fluid  ' />
           </div>
        </div>
       </div>
 
       </>
       
     );
}
 
export default CrearSecreto;