import React, { useContext, useEffect, useState } from 'react';
import Tabla from '../../components/TablaSecretos';
import FormEditarSecreto from '../../components/FormEditarSecreto'
import UpdateSecretoProvider  from '../../utils/context/Secreto';


const ListarSecretos = () => {

 
 
    return ( 
        <UpdateSecretoProvider>
        <div className='container'>     
            <div className='row'>
                <div className='col-md-5'>              
                    <Tabla/>               
                </div>
                <div className='col-md-7'>           
                <FormEditarSecreto  />            
                </div>
            </div>           
        </div>
        </UpdateSecretoProvider>
 
     );
}
 
export default ListarSecretos;