import React from 'react';

const ClaveUnica = ({guardarSecreto,handleInputChange}) => {


  
   
    return ( 
        <>
        <div className='form-group mb-4'>
           <label>Clave única <strong className='text-danger'>*</strong> </label>
             <input 
             type='password' 
             name='clave' 
             className='form-control' 
             placeholder='Ingresa tu clave única'
             required
             onChange={ handleInputChange }
             />
           </div>
           <div className='form-group mb-4'>
           <label>Confirmar clave única <strong className='text-danger'>*</strong> </label>
             <input 
             type='password' 
             name='confirmar' 
             className='form-control' 
             placeholder='Ingresa tu clave única nuevamente'
             required
             onChange={ handleInputChange }
             />
           </div>
           <button 
             type='submit' 
             className='form-control btn btn-primary mt-5'                
             onSubmit={ guardarSecreto } >
                 Guardar Secreto
             </button>
      
      </>

      
     );
}
 
export default ClaveUnica;
<></>